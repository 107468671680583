@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

:root {
  /* Uncomment to override Locator Core */
  --color-primary: #1C2443;
}

.headlineFont {
  font-family: "Libre Baskerville";
}

.bodyFont {
  font-family: "Roboto";
}

.colorPrimary {
  color: var(--color-primary);
}

.oswald {
  font-family: Oswald;
}

.lato {
  font-family: Lato;
}

.locator-form-header {
  @apply bg-yellow;
}

.locator-form-filters-container {
  @apply lg:bg-dark-blue;
}

.MuiAppBar-colorDefault {
  @apply bg-off-white #{!important};
}

.MuiButton-textPrimary {
  @apply lg:text-white #{!important};
}

.locator-form-filters-label {
  @apply lg:text-white;
}

.locator-form-search-input {
  @apply font-sans #{!important};
}

.locator-form-distance-select {
  .MuiSelect-root {
    @apply lg:text-dark-blue #{!important};
  }

  .MuiSelect-icon {
    @apply lg:text-dark-blue;
  }
}

.medications-available-container, .details-green-bg {
  @apply bg-green;
}

.contact-info-container {
  @apply bg-dark-blue;
}

.details-description {
  @apply bg-yellow text-dark-blue;
}

.quick-facts-container, .details-blue-bg {
  @apply bg-dark-blue;
}

.quick-facts-label {
  @apply text-light-blue;
}

.business-hours-container, .amenities-container {
  @apply bg-off-white;
}

.business-hours-header, .business-hours-day, .business-hours-value {
  @apply text-dark-blue;
}

.sponsors-container {
  @apply bg-off-white;

  .sponsors-label {
    @apply text-dark-blue;
  }
}

.detail-knockout-logo {
  @apply invisible;
}

.detail-back-button {
  @apply mt-0;
}

.details-container-content {
  @apply bg-dark-blue;
}

.locator-form-filters-placeholder {
  text-transform: capitalize;
}

.locator-form-filters-checkboxes {
  .MuiFormControlLabel-label {
    @apply lg:text-white #{!important};
  }
}

.locator-form-filters-select-containers {
  .MuiSelect-root, .MuiSelect-icon {
    @apply lg:text-white;
  }
}

@media only screen and (max-width: 768px) {
  .locator-form-header-title {
    font-size: 14px;
  }
}