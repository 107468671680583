@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
.sponsors-container {
  padding: 10px;
  border-top: 1px solid #eee;
  background-color: #f7f7f7;
  /*flex-direction: column;*/
  justify-content: center;
}

.sponsors-label {
  font-size: 14px;
  font-stretch: 100%;
  font-style: italic;
  font-weight: 200;
  color: #aab4ba;
  margin: 0 0 5px 5px;
}

.sponsor-img-wrapper {
  margin-right: 20px;
}

.sponsor-img {
  max-height: 20px;
}

.sponsors-logos {
  margin-right: 10px;
  align-items: center;
}

@media only screen and (min-width: 769px) {
  .sponsors-logos {
    margin-left: 30px;
  }

  .sponsors-container {
    padding: 15px;
    flex-direction: row;
    justify-content: flex-start;
  }

  .sponsors-label {
    font-size: 14px;
    font-stretch: 100%;
    font-style: italic;
    font-weight: 200;
    color: #aab4ba;
    margin: 0;
  }

  .sponsor-img-wrapper {
    margin-right: 40px;
  }

  .sponsor-img {
    max-height: 20px;
  }
}

.footer-container {
  padding: 30px;
  background-color: red;
}

.footer-novartis-logo {
  height: 20px;
  width: auto;
}

.footer-item {
  margin: 0 20px;
  color: var(--color-slate);
}

.footer-disclaimer {
  font-family: "Caecilia";
  font-size: 16px;
  color: var(--color-slate);
}

@media screen and (max-width: 767px) {
  .footer-novartis-logo {
    height: 18px;
    width: auto;
  }

  .footer-item {
    margin: 5px;
    font-size: 10px;
  }
}
.footer-container {
  padding: 30px;
  background-color: red;
}

.footer-novartis-logo {
  height: 20px;
  width: auto;
}

.footer-item {
  margin: 0 20px;
  color: var(--color-slate);
}

.footer-disclaimer {
  font-family: "Caecilia";
  font-size: 16px;
  color: var(--color-slate);
}

@media screen and (max-width: 767px) {
  .footer-novartis-logo {
    height: 18px;
    width: auto;
  }

  .footer-item {
    margin: 5px;
    font-size: 10px;
  }
}
:root{
  --color-primary: #1C2443
}

.headlineFont{
  font-family:"Libre Baskerville"
}

.bodyFont{
  font-family:"Roboto"
}

.colorPrimary{
  color:#1C2443;
  color:var(--color-primary)
}

.oswald{
  font-family:Oswald
}

.lato{
  font-family:Lato
}

.locator-form-header{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 191, 54, var(--tw-bg-opacity))
}

@media (min-width: 1024px){
  .locator-form-filters-container{
    --tw-bg-opacity: 1;
    background-color: rgba(28, 36, 67, var(--tw-bg-opacity))
  }
}

.MuiAppBar-colorDefault{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 245, 237, var(--tw-bg-opacity)) !important
}

@media (min-width: 1024px){
  .MuiButton-textPrimary{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
  }
}

@media (min-width: 1024px){
  .locator-form-filters-label{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }
}

.locator-form-search-input{
  font-family: Roboto, sans-serif !important
}

@media (min-width: 1024px){
  .locator-form-distance-select .MuiSelect-root{
    --tw-text-opacity: 1 !important;
    color: rgba(28, 36, 67, var(--tw-text-opacity)) !important
  }
}

@media (min-width: 1024px){
  .locator-form-distance-select .MuiSelect-icon{
    --tw-text-opacity: 1;
    color: rgba(28, 36, 67, var(--tw-text-opacity))
  }
}

.medications-available-container, .details-green-bg{
  --tw-bg-opacity: 1;
  background-color: rgba(103, 147, 63, var(--tw-bg-opacity))
}

.contact-info-container{
  --tw-bg-opacity: 1;
  background-color: rgba(28, 36, 67, var(--tw-bg-opacity))
}

.details-description{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 191, 54, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(28, 36, 67, var(--tw-text-opacity))
}

.quick-facts-container, .details-blue-bg{
  --tw-bg-opacity: 1;
  background-color: rgba(28, 36, 67, var(--tw-bg-opacity))
}

.quick-facts-label{
  --tw-text-opacity: 1;
  color: rgba(99, 182, 221, var(--tw-text-opacity))
}

.business-hours-container, .amenities-container{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 245, 237, var(--tw-bg-opacity))
}

.business-hours-header, .business-hours-day, .business-hours-value{
  --tw-text-opacity: 1;
  color: rgba(28, 36, 67, var(--tw-text-opacity))
}

.sponsors-container{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 245, 237, var(--tw-bg-opacity))
}

.sponsors-container .sponsors-label{
  --tw-text-opacity: 1;
  color: rgba(28, 36, 67, var(--tw-text-opacity))
}

.detail-knockout-logo{
  visibility: hidden
}

.detail-back-button{
  margin-top: 0px
}

.details-container-content{
  --tw-bg-opacity: 1;
  background-color: rgba(28, 36, 67, var(--tw-bg-opacity))
}

.locator-form-filters-placeholder{
  text-transform:capitalize
}

@media (min-width: 1024px){
  .locator-form-filters-checkboxes .MuiFormControlLabel-label{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
  }
}

@media (min-width: 1024px){
  .locator-form-filters-select-containers .MuiSelect-root, .locator-form-filters-select-containers .MuiSelect-icon{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }
}

@media only screen and (max-width: 768px){
  .locator-form-header-title{
    font-size:14px
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
  overflow: hidden;
}
